import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/category/:id",
    name: "category",
    component: () => import("../views/Category.vue"),
  },
  {
    path: "/post/:id",
    name: "post",
    component: () => import("../views/Post.vue"),
  },
  {
    path: "/page/:id/:slug",
    name: "page",
    component: () => import("../views/Page.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
