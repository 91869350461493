<template>
  <v-app>
    <v-app-bar color="blue-darken-4" :image="getAssets('appbar.svg')" elevation="1">
      <a v-if="$router.currentRoute.value.path != '/'" :href="'/'">
        <img style="height: 50px;" class="logo pr-10" src="whiteLogo.png" />
      </a>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon icon="mdi-facebook"></v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon icon="mdi-instagram"></v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon icon="mdi-youtube"></v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon icon="mdi-telegram"></v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon icon="mdi-whatsapp"></v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->

      <!-- If using vue-router -->
      <router-view />

    </v-main>

    <v-footer color="blue" class="d-flex flex-column pa-0">
      <div class="bg-orange pa-10 d-flex w-100 align-center">
        <strong>شركة مفخرة البناء</strong>
      </div>

      <div class="px-4 py-2 bg-blue text-center w-100">
        {{ new Date().getFullYear() }} — <strong>Mafkharat Albinaa</strong>
      </div>
    </v-footer>
    <notifications />
    <v-overlay style="z-index: 10000000000000" :model-value="$store.state.loading" scrim="#000000"
      class="align-center justify-center">
      <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

export default {
  name: "App",
  data: () => ({
    drawer: true,
    menu: [
      { title: "المواقع", route: "/", icon: "mdi-home-outline" },
      {
        title: "الصندوق",
        route: "/reports/incomes",
        icon: "mdi-cash-multiple",
      },
      {
        title: "الموظفين",
        route: "/employees",
        icon: "mdi-account-multiple-outline",
      },
      {
        title: "الغيابات",
        route: "/absences",
        icon: "mdi-card-account-details-outline",
      },
      {
        title: "الديون",
        route: "/loans",
        icon: "mdi-credit-card-marker-outline",
      },
      {
        title: "الاعدادات",
        route: "/settings",
        icon: "mdi-cog-outline",
        onlyAdmin: true,
      },
      {
        title: "الموقع الالكتروني",
        route: "/website",
        icon: "mdi-web",
      },
    ],
  }),
  mounted: function () {

  },
  methods: {
    getAssets(assetName) {
      let asset = require("./assets/" + assetName);
      return asset;
    },
    logout() {
      let con = confirm("هل انت متأكد من تسجيل الخروج");
      if (con) {
        localStorage.clear();
        location.href = "/";
      }
    },
  },
};
</script>

<style src="./style.css"></style>
